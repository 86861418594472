import * as actions from '../constants'

const inititalState = {
  loadingAbout: true,
  loadingAboutError: null,
  staff: null,
  foundIn: null,
  ha: null
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_HA_REQUEST:
      return {
        ...state,
        ha: null,
        loadingAbout: true,
        loadingAboutError: null
      }
    case actions.LOAD_HA_SUCCESS:
      return {
        ...state,
        ha: action.payload[0],
        loadingAbout: false,
        loadingAboutError: null
      }
    case actions.LOAD_HA_FAILURE:
      return {
        ...state,
        loadingAbout: false,
        loadingAboutError: action.payload
      }
    case actions.LOAD_STAFF_REQUEST:
      return {
        ...state,
        staff: null,
        loadingAbout: true,
        loadingAboutError: null
      }
    case actions.LOAD_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload[0],
        loadingAbout: false,
        loadingAboutError: null
      }
    case actions.LOAD_STAFF_FAILURE:
      return {
        ...state,
        loadingAbout: false,
        loadingAboutError: action.payload
      }
    case actions.LOAD_FOUNDIN_REQUEST:
      return {
        ...state,
        foundIn: null,
        loadingAbout: true,
        loadingAboutError: null
      }
    case actions.LOAD_FOUNDIN_SUCCESS:
      return {
        ...state,
        foundIn: action.payload[0],
        loadingAbout: false,
        loadingAboutError: null
      }
    case actions.LOAD_FOUNDIN_FAILURE:
      return {
        ...state,
        loadingAbout: false,
        loadingAboutError: action.payload
      }
    default:
      return state
  }
}
