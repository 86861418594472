import React, { Component } from 'react';


const Cover = (props) => <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 876 1512.933">
  <g>
    <path d="M161.739,78.577c-7.862,31.446-16.13,63.1-25.115,93.427h64.294V192.1H0V172H114.523c10.108-32.569,19.381-65.762,26.119-99.454ZM114.523.219V41.407h73.335V62.5H18.083v-21.1H91.417V.219ZM84.385,158.944,61.28,164.971c-4.492-29.2-11.226-56.427-19.087-83.381L65.3,74.559A660.373,660.373,0,0,1,84.385,158.944Z" transform="translate(0 3.068)" fill="#060001"/>
    <path d="M136.014,36.2c0,56.154,30.89,101.808,90.413,136.624l-18.083,19.087c-43.8-28.077-71.913-61.27-85.39-99.454-16.846,39.308-46.731,72.382-89.408,100.459l-14.064-21.1C81.251,132.512,112.909,87.862,112.909,36.2V21.131H62.679V1.04h73.335Z" transform="translate(200.523 13.298)" fill="#060001"/>
    <path d="M124.278,77.626a99.4,99.4,0,0,1-19.087-18.083V91.69h-21.1V58.539A290.912,290.912,0,0,1,49.938,87.672L39.892,69.589C55.615,60.6,67.959,50.683,78.067,39.452H43.911V21.369H84.094V.273h21.1v21.1h33.151V39.452H109.209c6.738,7.862,15.007,14.358,25.115,21.1ZM136.333,204.2H119.255l-10.046-19.087h21.1c3.369,0,6.028-1.654,6.028-5.023v-37.17H42.906V124.842H243.824v18.083H157.429v42.193C157.429,197.471,150.933,204.2,136.333,204.2ZM63,194.158,45.92,181.1c16.846-10.108,32.616-21.565,47.216-36.165l16.073,11.05A177.536,177.536,0,0,1,63,194.158ZM214.691,115.8H73.044V97.718H215.7V115.8Zm22.1-29.133A205.419,205.419,0,0,1,198.617,56.53V91.69H178.525V58.539c-10.108,10.108-20.8,19.025-33.151,29.133L135.328,69.589A156.518,156.518,0,0,0,172.5,39.452H147.383V21.369h31.142V.273h20.092v21.1h46.211V39.452H206.654a160.686,160.686,0,0,0,42.193,30.138Zm8.037,91.417-14.064,15.069c-19.092-10.108-37.639-21.684-52.239-35.161L195.6,145.938C210.2,159.415,226.859,169.1,244.828,178.085Z" transform="translate(405.14)" fill="#060001"/>
    <path d="M157.712.237h22.1V63.526H243.1v21.1H179.813v89.408H264.2v20.092H60.267V174.031H100.45V41.425h22.1v131.6h35.161Z" transform="translate(611.802 2.045)" fill="#060001"/>
    <path d="M2.533,170.722l16.073-19.087c14.6,13.477,30.132,20.092,49.225,20.092,16.846,0,28.128-7.738,28.128-20.092h0c0-11.231-6.965-17.372-36.165-24.11C26.1,119.664,7.556,109.51,7.556,80.31h0C7.556,53.356,29.353,34.1,60.8,34.1c22.462,0,41.538,6.615,57.261,20.092L105,75.287C90.4,65.179,76.4,59.213,61.8,59.213c-15.723,0-25.115,7.856-25.115,19.087h0c0,12.354,5.842,17.372,36.165,24.11,33.692,7.862,51.234,20.38,51.234,46.211h0c0,29.2-23.688,47.216-56.257,47.216C44.247,195.837,20.5,187.569,2.533,170.722Z" transform="translate(26.6 351.936)" fill="#060001"/>
    <path d="M63.6,60.2H14.379V34.081H142.967V60.2H92.737V191.8H63.6Z" transform="translate(149.368 352.958)" fill="#060001"/>
    <path d="M28.01,125.5V34.081H56.139v90.413c0,29.2,14.471,45.206,39.179,45.206s39.179-15,39.179-44.2V34.081h28.128v89.408c0,47.169-26.877,70.321-67.307,70.321C53.764,194.933,28.01,171.544,28.01,125.5Z" transform="translate(284.416 352.958)" fill="#060001"/>
    <path d="M42.336,35.063H100.6c49.415,0,83.381,33.435,83.381,78.358h0c0,43.8-33.965,78.358-83.381,78.358H42.336Zm28.128,24.11V165.659h32.147c32.569,0,54.248-21.915,54.248-52.239h0c0-30.323-20.555-54.248-54.248-54.248Z" transform="translate(436.852 353.985)" fill="#060001"/>
    <path d="M57.59,35.063H85.718V191.778H57.59Z" transform="translate(593.383 353.985)" fill="#060001"/>
    <path d="M63.656,116.493h0c0-44.923,33.079-82.376,81.372-82.376S227.4,71.688,227.4,115.488h0c0,43.8-34.084,80.367-82.376,80.367C96.736,196.978,63.656,160.293,63.656,116.493Zm134.615,0h0c0-31.446-22.8-56.257-54.248-56.257S90.78,85.165,90.78,115.488h0c0,30.323,22.8,56.257,54.248,56.257S198.271,146.816,198.271,116.493Z" transform="translate(646.587 350.913)" fill="#060001"/>
    <path d="M15.272,52.011H41.391l83.381,108.5V52.011H151.9V208.727H128.79L42.4,96.213V208.727H15.272Z" transform="translate(155.508 527.908)" fill="#060001"/>
    <path d="M97.012,51.029h26.119l69.317,158.725H163.315L147.242,171.58H72.9L57.834,209.754H29.705ZM137.2,146.465,110.072,84.181,81.944,146.465Z" transform="translate(301.809 526.881)" fill="#060001"/>
    <path d="M45.833,63H75.971V94.143H45.833Z" transform="translate(465.501 642.492)" fill="#060001"/>
    <path d="M50.882,52.011H167.414v24.11h-88.4v40.183h78.358V141.42H79.011v43.2h89.408v24.11H50.882Z" transform="translate(519.723 527.908)" fill="#060001"/>
    <path d="M63.656,133.441h0c0-44.923,33.079-82.376,81.372-82.376S227.4,88.637,227.4,132.437h0c0,43.8-34.084,80.367-82.376,80.367C96.736,213.927,63.656,177.241,63.656,133.441Zm134.615,0h0c0-31.446-22.8-56.257-54.248-56.257S90.78,102.114,90.78,132.437h0c0,30.323,22.8,56.257,54.248,56.257S198.271,163.765,198.271,133.441Z" transform="translate(646.587 524.836)" fill="#060001"/>
    <path d="M161.739,148.2c-7.862,31.446-16.13,63.1-25.115,93.427h64.294v20.092H0V241.624H114.523c10.108-32.569,19.381-66.766,26.119-100.459ZM114.523,68.835v42.193h73.335v20.092H18.083V111.027H91.417V68.835ZM84.385,227.559,61.28,234.591a660.372,660.372,0,0,0-19.087-84.385L65.3,143.174A660.374,660.374,0,0,1,84.385,227.559Z" transform="translate(0 708.989)" fill="#060001"/>
    <path d="M136.014,103.789c0,56.154,30.89,102.813,90.413,137.628L208.344,260.5c-43.8-28.077-71.913-62.274-85.39-100.459-16.846,39.308-46.731,73.386-89.408,101.463l-14.064-21.1c61.769-39.308,93.427-84.962,93.427-136.624V90.729H62.679V70.637h73.335Z" transform="translate(200.523 720.246)" fill="#060001"/>
    <path d="M124.278,146.242a99.4,99.4,0,0,1-19.087-18.083V161.31h-21.1V127.154a290.907,290.907,0,0,1-34.156,29.133L39.892,138.2c15.723-8.985,28.067-18.907,38.174-30.138H43.911V89.985H84.094v-21.1h21.1v21.1h33.151v18.083H109.209c6.738,7.862,15.007,14.358,25.115,21.1Zm12.055,127.583H119.255l-10.046-19.087h21.1a5.977,5.977,0,0,0,6.028-6.028V211.54H42.906V193.457H243.824V211.54H157.429v43.2C157.429,267.091,150.933,273.824,136.333,273.824ZM63,263.778,45.92,249.714c16.846-10.108,32.616-21.565,47.216-36.165L109.209,225.6A177.538,177.538,0,0,1,63,263.778Zm151.693-79.362H73.044V166.333H215.7v18.083Zm22.1-29.133a205.42,205.42,0,0,1-38.174-30.138V161.31H178.525V127.154c-10.108,10.108-20.8,19.025-33.151,29.133L135.328,138.2a156.518,156.518,0,0,0,37.17-30.138H147.383V89.985h31.142v-21.1h20.092v21.1h46.211v18.083H206.654A160.686,160.686,0,0,0,248.846,138.2Zm8.037,92.422-14.064,14.064c-19.092-10.108-37.639-20.679-52.239-34.156l17.078-13.06C210.2,228.03,226.859,238.72,244.828,247.7Z" transform="translate(405.14 705.921)" fill="#060001"/>
    <path d="M157.712,68.853h22.1v63.289H243.1v21.1H179.813v90.413H264.2v20.092H60.267V243.651H100.45V111.045h22.1v131.6h35.161Z" transform="translate(611.802 707.966)" fill="#060001"/>
    <path d="M2.533,240.356l17.078-20.092c14.6,13.477,30.132,20.092,49.225,20.092,16.846,0,28.128-7.738,28.128-20.092h0c0-11.231-6.965-17.372-36.165-24.11-33.692-7.861-52.239-18.016-52.239-47.216h0c0-26.954,21.8-45.206,53.243-45.206,22.462,0,41.538,6.615,57.261,20.092L105,144.92c-14.6-10.108-28.6-16.073-43.2-16.073-15.723,0-25.115,7.856-25.115,19.087h0c0,12.354,5.842,18.376,36.165,25.115,33.692,7.862,51.234,20.38,51.234,46.211h0c0,29.2-23.688,47.216-56.257,47.216A96.318,96.318,0,0,1,2.533,240.356Z" transform="translate(26.6 1056.839)" fill="#060001"/>
    <path d="M63.6,129.815H14.379V103.7H142.967v26.119H92.737v130.6H63.6Z" transform="translate(149.368 1058.884)" fill="#060001"/>
    <path d="M28.01,194.109V103.7H56.139V193.1c0,29.2,14.471,45.206,39.179,45.206s39.179-15,39.179-44.2V103.7h28.128v88.4c0,47.169-26.877,71.326-67.307,71.326C53.764,264.549,28.01,240.155,28.01,194.109Z" transform="translate(284.416 1058.884)" fill="#060001"/>
    <path d="M42.336,103.678H100.6c49.415,0,83.381,33.435,83.381,78.358h0c0,43.8-33.965,78.358-83.381,78.358H42.336Zm28.128,25.115V235.279h32.147c32.569,0,54.248-22.92,54.248-53.243h0c0-30.323-20.555-53.243-54.248-53.243Z" transform="translate(436.852 1059.907)" fill="#060001"/>
    <path d="M57.59,103.678H85.718V260.394H57.59Z" transform="translate(593.383 1059.907)" fill="#060001"/>
    <path d="M63.656,185.1h0c0-44.923,33.079-81.372,81.372-81.372S227.4,140.3,227.4,184.1h0c0,43.8-34.084,81.372-82.376,81.372C96.736,266.594,63.656,228.9,63.656,185.1Zm134.615,0h0c0-31.446-22.8-56.257-54.248-56.257S90.78,153.776,90.78,184.1h0c0,30.323,22.8,56.257,54.248,56.257S198.271,215.427,198.271,185.1Z" transform="translate(646.587 1056.839)" fill="#060001"/>
    <path d="M15.272,120.627H41.391l83.381,108.5v-108.5H151.9V277.343H128.79L42.4,165.833V277.343H15.272Z" transform="translate(155.508 1233.829)" fill="#060001"/>
    <path d="M97.012,120.645h26.119l69.317,157.72H163.315l-16.073-38.174H72.9L57.834,278.365H29.705ZM137.2,216.081l-27.124-63.289L82.948,216.081Z" transform="translate(301.809 1232.807)" fill="#060001"/>
    <path d="M45.833,131.617H75.971v31.142H45.833Z" transform="translate(465.501 1348.413)" fill="#060001"/>
    <path d="M50.882,120.627H167.414v25.115h-88.4v40.183h78.358v24.11H79.011v43.2h89.408v24.11H50.882Z" transform="translate(519.723 1233.829)" fill="#060001"/>
    <path d="M63.656,202.052h0c0-44.923,33.079-81.372,81.372-81.372S227.4,157.248,227.4,201.048h0c0,43.8-34.084,81.372-82.376,81.372C96.736,283.542,63.656,245.852,63.656,202.052Zm134.615,0h0c0-31.446-22.8-56.257-54.248-56.257S90.78,170.725,90.78,201.048h0c0,30.323,22.8,56.257,54.248,56.257S198.271,232.375,198.271,202.052Z" transform="translate(646.587 1230.762)" fill="#060001"/>
  </g>
</svg>

export default Cover
