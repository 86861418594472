import * as actions from '../constants'

const inititalState = {
  loadingWork: true,
  loadingWorkError: null,
  data: null
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_WORK_REQUEST:
      return {
        ...state,
        data: null,
        loadingWork: true,
        loadingWorkError: null
      }
    case actions.LOAD_WORK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingWork: false,
        loadingWorkError: null
      }
    case actions.LOAD_WORK_FAILURE:
      return {
        ...state,
        loadingWork: false,
        loadingWorkError: action.payload
      }
    default:
      return state
  }
}
