import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import windowSize from 'react-window-size';


class SideMenu extends Component {
  render() {
    return (
      <div className={"side-menu "+this.props.className}>
        <div className={"menu-about menu-item"}>
          <Link to="/about">
            ABOUT US
          </Link>
        </div>
        <div className={"menu-works menu-item"}>
          <a href="/works">
            WORKS
          </a>
        </div>
        <div className={"menu-contact menu-item"}>
          <Link to="/contact">
            CONTACT
          </Link>
        </div>
        <div className={"menu-shop menu-item"}>
          <a href="https://forsampleshop.com" target={"_blank"}>
            SHOP<br />
            FOR SAMPLE
          </a>
        </div>
      </div>
    );
  }
}

export default SideMenu;

