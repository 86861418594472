import React, { Component } from 'react'
import Swiper from 'react-id-swiper'

class Icons extends Component {
  slideCounter = 0
  maxCounter = 30
  handleMouse(){
    let {icons} = this.props
    if(!this.swiper){
      return
    }
    if(this.swiper.autoplay.running){
      this.swiper.autoplay.stop()
    }
    if(this.swiper.autoplay.delay === 100){
      this.swiper.params.autoplay = {
        delay: 1000
      }
    }else{
      this.swiper.params.autoplay = {
        delay: 100
      }
    }
    this.slideCounter = 0
    this.maxCounter = 15 + Math.floor(2*Math.random()*icons.images.length)
    this.swiper.autoplay.start()
  }
  render() {
    let {icons} = this.props
    if(!icons || !icons.images){
      return null
    }
    let params = {
      effect: 'fade',
      loop: true,
      noSwiping: true,
      speed: 30,
      autoplay: {
        delay: 1500
      },
      fadeEffect: {
        crossFade: true
      }
    }
    return <div
      className={"home-icons"}
      onMouseEnter={this.handleMouse.bind(this)}
      onMouseLeave={()=>{
        if(this.swiper){
          this.swiper.autoplay.stop()
          this.swiper.params.autoplay = {
            delay: 1000
          }
          this.swiper.autoplay.start()
        }
      }}
      onClick={()=>{
        console.log('cl')
        if(this.swiper){
          this.swiper.autoplay.stop()
          if(!this.fast){
            this.swiper.params.autoplay = {
              delay: 100
            }
            this.fast = true
          }else{
            this.swiper.params.autoplay = {
              delay: 1500
            }
            this.fast = false
          }
          this.swiper.autoplay.start()
        }
      }}
    >
      <Swiper {...params} ref={node => {
        if(node) this.swiper = node.swiper
      }}>
        {
          icons.images.map(img=>{
            return <div key={img.id} style={{
              backgroundImage: `url(${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,w_500,h_836)`
            }}>
            </div>
          })
        }
      </Swiper>
    </div>
  }
}

export default Icons

