import React, {Component} from 'react';
import {connect} from 'react-redux'
import {loadWorks} from "../../actions/works";
import './Works.css'
import BodyClassName from 'react-body-classname';
import {LENS} from "../../constants";
import {Link} from 'react-router-dom';

class Works extends Component {
  state = {
    showEntry: true,
    activeIndex: 0,
  }

  componentDidMount() {
    this.props.loadWorks()
  }

  renderWorks() {
    let {works} = this.props
    if (!works) {
      return null
    }
    return works.map((work) => {
      if (!work.cover) {
        return null
      }
      // return <div key={work.id} className={"work-grid"}>
      //   <Link to={`/works/${work.id}`} style={{
      //     backgroundImage:`url('${work.cover.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,h_320,w_400')`
      //   }} />
      // </div>
      let targetLink = `/works/${work.id}`
      if(!work.images || work.images.length === 0){
        targetLink = `/works`
      }
      return <div key={work.id} className={"work-grid"}>
        <Link to={targetLink} style={{
          backgroundImage:`url('${work.cover.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,h_640,w_800')`
        }} />
      </div>
    })
  }

  render() {
    let {windowWidth, contact} = this.props
    let contentWidth = windowWidth - (LENS[0] + LENS[1] + LENS[2] + LENS[3] + LENS[0]) - 10
    let leftColWidth = LENS[0] + LENS[1] + LENS[2] + LENS[3] + LENS[1]
    return <BodyClassName className={`works`}>
      <div className={"works-content"}>
        <div className={"mcontent-navs"}>
          <Link className={"nav-home"} to={'/'}>HOME</Link>
        </div>
        <div className={"works-container"}>
          <div className={"works-content"}>
            {this.renderWorks()}
          </div>
        </div>
      </div>
    </BodyClassName>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    works: state.works.data,
    loadingWorks: state.works.loadingWorks,
    loadingWorksError: state.works.loadingWorksError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWorks: () => dispatch(loadWorks())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Works)


