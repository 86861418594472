import React, { Component } from 'react'
import { connect } from 'react-redux'
import {loadHomepage, loadIcons} from "../../actions/home"
import './Home.css'
import BodyClassName from 'react-body-classname'
import Cover from './Cover'
import MCover from './MCover'
import {LENS} from "../../constants"
import windowSize from 'react-window-size'
import Icons from './Icons'


class Home extends Component {
  state = {
  }
  componentDidMount(){
    this.props.loadHomepage()
    this.props.loadIcons()
    this.setState({
      translate: Math.floor( -200 + this.props.windowWidth * ( - Math.random())*1)
    })
    this.timer = setInterval(()=>{
      this.setState({
        translate: 0
      })
    }, 500)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  renderContent() {
    let {contact} = this.props
    if(!contact) {
      return null
    }
    let segs = contact.content.split('\n').map((a, i)=>{
       return <div key={i}>{a}</div>
    })
    return <div className={"content-text-lines"}>{segs}</div>
  }
  render() {
    let {icons, windowWidth, windowHeight, leftColWidth, rightColWidth, contentWidth} = this.props
    return <BodyClassName className={`home`}>
      <div className={"home-content"}>
        <div className={"content-container"}>
          <div className={"content-text-left"} style={{width:leftColWidth}}>STUDIO NA.EO</div>
          <div className={"content-text-right"} style={{width:rightColWidth}}>{this.renderContent()}</div>
        </div>
        <div className={"home-cover"}>
          <div>
            <Cover width={contentWidth} />
          </div>
        </div>
        <div className={"mhome-cover"}>
          <div style={{
            transform:`translate(${this.state.translate}px, 0px)`
          }}>
            <MCover height={windowHeight - 198 -24} />
          </div>
        </div>
        {icons &&
          <Icons icons={icons}/>
        }
      </div>
    </BodyClassName>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    icons: state.home.icons,
    contact: state.home.homepage,
    loadingHomeSlides: state.home.loadingHomeSlides,
    loadingHomeSlidesError: state.home.loadingHomeSlidesError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadIcons: ()=>dispatch(loadIcons()),
    loadHomepage: ()=>dispatch(loadHomepage())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(windowSize(Home))


