import * as actions from '../constants'

export const loadContact = () => dispatch=>{
  dispatch({
    type: actions.LOAD_CONTACT_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=CONTACT`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_CONTACT_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_CONTACT_FAILURE,
      payload: error.message
    })
  })
}

export const loadIcons = () => dispatch=>{
  dispatch({
    type: actions.LOAD_ICONS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=ICONS`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_ICONS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_ICONS_FAILURE,
      payload: error.message
    })
  })
}


export const loadHomepage = () => dispatch=>{
  dispatch({
    type: actions.LOAD_HOMEPAGE_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=HOMEPAGE`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_HOMEPAGE_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_HOMEPAGE_FAILURE,
      payload: error.message
    })
  })
}
