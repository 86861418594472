import * as actions from '../constants'

export const loadWorks = () => dispatch=>{
  dispatch({
    type: actions.LOAD_WORKS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/works?visible=true&_limit=1000&_sort=order:DESC`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_WORKS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_WORKS_FAILURE,
      payload: error.message
    })
  })
}