import * as actions from '../constants'

export const loadFoundIn = () => dispatch=>{
  dispatch({
    type: actions.LOAD_FOUNDIN_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=${encodeURIComponent('FOUND IN 2015')}`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_FOUNDIN_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_FOUNDIN_FAILURE,
      payload: error.message
    })
  })
}

export const loadStaff = () => dispatch=>{
  dispatch({
    type: actions.LOAD_STAFF_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=FOUNDERS`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_STAFF_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_STAFF_FAILURE,
      payload: error.message
    })
  })
}

export const loadHA = () => dispatch=>{
  dispatch({
    type: actions.LOAD_HA_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages?title=${encodeURIComponent('HONORS & AWARDS')}`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_HA_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_HA_FAILURE,
      payload: error.message
    })
  })
}
