import React, { Component } from 'react';
import { connect } from 'react-redux'
import './Contact.css'
import {loadContact} from "../../actions/home";
import BodyClassName from 'react-body-classname';
import { Link } from 'react-router-dom';

class Contact extends Component {
  state={}
  componentDidMount(){
    this.props.loadContact()
  }
  handleScroll(){
    let scrollTop = this.ref.scrollTop
    this.setState({
      scrollTop
    });
  }
  renderContent() {
    let {contact} = this.props
    if(!contact) {
      return null
    }
    let segs = contact.content.split('\n').map((a, i)=>{
       return <div key={i}>{a}</div>
    })
    return <div className={"content-text-lines"}>{segs}</div>
  }
  render() {
    let {scrollTop} = this.state
    let {rightColWidth, contact, leftColWidth} = this.props
    return <BodyClassName className={`contact ${scrollTop>20?'scrolling':''}`}>
      <div className={"contact-content"}>
        <div className={"mcontent-navs"}>
          <Link className={"nav-home"} to={'/'}>HOME</Link>
        </div>
        <div className={"content-container"}>
          <div className={"content-text-left"} style={{width:leftColWidth}}>BEIJING, CHINA</div>
          <div className={"content-text-right"} style={{width:rightColWidth}}>{this.renderContent()}</div>
        </div>
        <div className={"contact-cover"} ref={ref=>this.ref=ref} onScroll={this.handleScroll.bind(this)}>
          {
            contact && contact.images && contact.images[1] &&
            <img src={`${contact.images[1].url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,w_2000`}/>
          }
        </div>
        <div className={"mcontact-cover"} ref={ref=>this.ref=ref} onScroll={this.handleScroll.bind(this)}>
          {
            contact && contact.images && contact.images[0] &&
            <img src={`${contact.images[0].url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,w_800`}/>
          }
        </div>
      </div>
    </BodyClassName>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contact: state.home.contact,
    loadingHomeSlides: state.home.loadingHomeSlides,
    loadingHomeSlidesError: state.home.loadingHomeSlidesError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadContact: ()=>dispatch(loadContact())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact)


