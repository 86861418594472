import { combineReducers } from 'redux';
import home from './home';
import work from './work'
import works from './works'
import about from './about'

export default combineReducers({
  about,
  home,
  work,
  works,
});