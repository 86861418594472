export const API_ENDPOINT = "https://api.studionaeo.com"

export const LOAD_ICONS_REQUEST = 'LOADING_ICONS_REQUEST'
export const LOAD_ICONS_FAILURE = 'LOADING_ICONS_FAILURE'
export const LOAD_ICONS_SUCCESS = 'LOADING_ICONS_SUCCESS'

export const LOAD_CONTACT_REQUEST = 'LOADING_CONTACT_REQUEST'
export const LOAD_CONTACT_FAILURE = 'LOADING_CONTACT_FAILURE'
export const LOAD_CONTACT_SUCCESS = 'LOADING_CONTACT_SUCCESS'

export const LOAD_WORK_REQUEST = 'LOADING_WORK_REQUEST'
export const LOAD_WORK_FAILURE = 'LOADING_WORK_FAILURE'
export const LOAD_WORK_SUCCESS = 'LOADING_WORK_SUCCESS'

export const LOAD_WORKS_REQUEST = 'LOADING_WORKS_REQUEST'
export const LOAD_WORKS_FAILURE = 'LOADING_WORKS_FAILURE'
export const LOAD_WORKS_SUCCESS = 'LOADING_WORKS_SUCCESS'

export const LOAD_FOUNDIN_REQUEST = 'LOADING_FOUNDIN_REQUEST'
export const LOAD_FOUNDIN_FAILURE = 'LOADING_FOUNDIN_FAILURE'
export const LOAD_FOUNDIN_SUCCESS = 'LOADING_FOUNDIN_SUCCESS'

export const LOAD_STAFF_REQUEST = 'LOADING_STAFF_REQUEST'
export const LOAD_STAFF_FAILURE = 'LOADING_STAFF_FAILURE'
export const LOAD_STAFF_SUCCESS = 'LOADING_STAFF_SUCCESS'

export const LOAD_HA_REQUEST = 'LOADING_HA_REQUEST'
export const LOAD_HA_FAILURE = 'LOADING_HA_FAILURE'
export const LOAD_HA_SUCCESS = 'LOADING_HA_SUCCESS'

export const LOAD_HOMEPAGE_REQUEST = 'LOADING_HOMEPAGE_REQUEST'
export const LOAD_HOMEPAGE_FAILURE = 'LOADING_HOMEPAGE_FAILURE'
export const LOAD_HOMEPAGE_SUCCESS = 'LOADING_HOMEPAGE_SUCCESS'

export const TEXTS = ['STUDIO', 'NA.', 'EO', '立入禁止']
export const LENS = [80.47, 33.34, 33.81, 86]
