import * as actions from '../constants'

const inititalState = {
  loadingHomeSlides: true,
  loadingHomeSlidesError: null,
  contact: null,
  homepage: null,
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_CONTACT_REQUEST:
    return {
      ...state,
      contact: null,
      loadingContact: true,
      loadingContactError: null
    }
    case actions.LOAD_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload[0],
        loadingContact: false,
        loadingContactError: null
      }
    case actions.LOAD_CONTACT_FAILURE:
      return {
        ...state,
        loadingContact: false,
        loadingContactError: action.payload
      }
    case actions.LOAD_HOMEPAGE_REQUEST:
      return {
        ...state,
        homepage: null,
        loadingHomepage: true,
        loadingHomepageError: null
      }
    case actions.LOAD_HOMEPAGE_SUCCESS:
      return {
        ...state,
        homepage: action.payload[0],
        loadingHomepage: false,
        loadingHomepageError: null
      }
    case actions.LOAD_HOMEPAGE_FAILURE:
      return {
        ...state,
        loadingHomepage: false,
        loadingHomepageError: action.payload
      }
    case actions.LOAD_ICONS_REQUEST:
      return {
        ...state,
        icons: null,
        loadingHomeSlides: true,
        loadingHomeSlidesError: null
      }
    case actions.LOAD_ICONS_SUCCESS:
      let icons = {}
      if(action.payload[0] && action.payload[0].images){
        icons=action.payload[0]
        icons.images.sort(()=>Math.random()>0.5?-1:1)
      }
      return {
        ...state,
        icons,
        loadingHomeSlides: false,
        loadingHomeSlidesError: null
      }
    case actions.LOAD_ICONS_FAILURE:
      return {
        ...state,
        loadingHomeSlides: false,
        loadingHomeSlidesError: action.payload
      }
    default:
      return state
  }
}
