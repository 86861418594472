import * as actions from '../constants'

const inititalState = {
  loadingWorks: true,
  loadingWorksError: null,
  data: [],
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_WORKS_REQUEST:
      return {
        ...state,
        data: [],
        loadingWorks: true,
        loadingWorksError: null
      }
    case actions.LOAD_WORKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingWorks: false,
        loadingWorksError: null
      }
    case actions.LOAD_WORKS_FAILURE:
      return {
        ...state,
        loadingWorks: false,
        loadingWorksError: action.payload
      }
    default:
      return state
  }
}
