import React, { Component } from 'react';



const Cover = (props) => <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 749.498 434">
    <g transform="translate(0 434) rotate(-90)">
      <path d="M79.932,39.156C76.047,54.7,72.161,70.241,67.721,85.228h31.64V95.22H0V85.228H56.619a495.921,495.921,0,0,0,12.767-49.4ZM56.619.3V20.838H92.7V30.83H8.881V20.838H44.962V.3ZM41.631,78.567,30.53,81.9a325.209,325.209,0,0,0-9.436-41.632l11.1-3.331A325.211,325.211,0,0,1,41.631,78.567Z" transform="translate(0 1.365)" fill="#060001"/>
      <path d="M77.329,18.508c0,27.754,14.987,50.513,44.407,67.721l-8.881,9.437c-21.648-13.877-35.526-30.53-42.187-49.4C62.342,65.69,47.354,82.343,26.261,96.22L19.6,85.673C50.13,66.245,65.672,44.042,65.672,18.508V11.292H40.693V1.3H77.329Z" transform="translate(89.537 5.916)" fill="#060001"/>
      <path d="M81.232,38.3A49.019,49.019,0,0,1,71.8,29.42v16.1H61.8V28.865A147.826,147.826,0,0,1,44.6,43.3l-5-8.881A74.925,74.925,0,0,0,58.473,19.428H41.82V10.547H61.8V0H71.8V10.547H88.448v8.881H74.015A57.715,57.715,0,0,0,86.227,29.975Zm6.106,62.725H79.011l-5-9.437H84.562a2.622,2.622,0,0,0,2.775-2.775V70.5H41.265V61.615h99.361V70.5H97.884V91.589C97.884,97.7,94.554,101.026,87.337,101.026Zm-36.081-5-8.326-6.661A117.848,117.848,0,0,0,66.244,71.606l7.771,5.551A87.5,87.5,0,0,1,51.257,96.03Zm74.937-38.856H56.253V48.293h70.5v8.881Zm11.1-14.432a101.951,101.951,0,0,1-18.873-14.987V45.517h-9.992V28.865c-5,5-10.547,9.436-16.653,14.432l-5-8.881A77.542,77.542,0,0,0,105.1,19.428H92.888V10.547h15.542V0h9.992V10.547h22.759v8.881H122.308a78.165,78.165,0,0,0,20.538,14.987Zm3.886,45.517-7.216,7.216c-9.437-5-18.318-10.547-25.534-17.208l8.326-6.106C123.973,78.822,132.3,83.818,141.181,88.259Z" transform="translate(180.903)" fill="#060001"/>
      <path d="M108.093.2h11.1V31.285h31.085V41.832H119.194V86.239h41.631V96.23H59.8V86.239H79.783v-65.5h11.1V85.684h17.208V.2Z" transform="translate(273.174 0.91)" fill="#060001"/>
      <path d="M2.6,101.566l7.771-9.436c7.216,6.661,14.987,9.992,24.424,9.992,8.326,0,13.877-3.886,13.877-9.992h0c0-5.551-3.331-8.326-17.763-11.657C14.257,76.587,4.82,71.591,4.82,57.159h0c0-13.322,11.1-22.759,26.644-22.759q16.653,0,28.309,9.992l-6.661,9.992c-7.216-5-13.877-7.771-21.093-7.771-7.771,0-12.767,3.886-12.767,9.436h0c0,6.106,3.331,8.881,18.318,12.212,16.653,3.886,24.979,9.992,24.979,22.759h0c0,14.432-11.657,23.314-27.754,23.314A47.266,47.266,0,0,1,2.6,101.566Z" transform="translate(11.832 157.06)" fill="#060001"/>
      <path d="M39.024,47.267H14.6V34.5H77.88V47.267H53.456v64.945H39.024Z" transform="translate(66.669 157.515)" fill="#060001"/>
      <path d="M27.8,79.462V34.5H41.677V78.907c0,14.432,7.216,22.2,19.428,22.2s19.428-7.216,19.428-21.648V34.5H94.41V78.352c0,23.314-13.322,34.97-33.305,34.97C40.567,113.878,27.8,102.221,27.8,79.462Z" transform="translate(126.949 157.516)" fill="#060001"/>
      <path d="M42.7,34.6H71.565c24.424,0,41.076,16.653,41.076,38.856h0c0,21.648-16.653,38.856-41.076,38.856H42.7ZM56.577,46.812V99.545H72.12c16.1,0,27.2-11.1,27.2-26.089h0c0-14.987-10.547-26.644-27.2-26.644Z" transform="translate(194.996 157.971)" fill="#060001"/>
      <path d="M58,34.6H71.877v77.712H58Z" transform="translate(264.743 157.971)" fill="#060001"/>
      <path d="M63.2,74.821h0c0-22.2,16.653-40.521,40.521-40.521s40.521,18.318,40.521,39.966h0c0,21.648-16.653,39.966-40.521,39.966C79.853,114.788,63.2,96.47,63.2,74.821Zm66.61,0h0c0-15.542-11.1-27.754-26.644-27.754s-26.089,12.212-26.089,27.2h0c0,14.987,11.1,27.754,26.644,27.754S129.81,89.809,129.81,74.821Z" transform="translate(288.647 156.603)" fill="#060001"/>
      <path d="M15.2,51.6H27.967L69.6,105.444V51.6H82.921v77.712H71.264L28.522,73.8v55.509H15.2V51.6Z" transform="translate(69.412 235.587)" fill="#060001"/>
      <path d="M62.805,51.5H75.572l34.415,78.267H95.555l-7.771-18.873H51.148l-7.771,18.873H29.5ZM82.788,98.682,69.466,67.6,55.589,98.682Z" transform="translate(134.73 235.132)" fill="#060001"/>
      <path d="M45.5,62.8H60.487V78.342H45.5Z" transform="translate(207.688 286.644)" fill="#060001"/>
      <path d="M50.8,51.6h57.729V63.812H64.677V83.8h38.856V96.007H64.677V117.1h44.407v12.212H50.8Z" transform="translate(231.961 235.587)" fill="#060001"/>
      <path d="M63.2,91.821h0c0-22.2,16.653-40.521,40.521-40.521s40.521,18.318,40.521,39.966h0c0,21.648-16.653,39.966-40.521,39.966C79.853,131.788,63.2,113.47,63.2,91.821Zm66.61,0h0c0-15.542-11.1-27.754-26.644-27.754s-26.089,12.212-26.089,27.2h0c0,14.987,11.1,27.754,26.644,27.754S129.81,106.809,129.81,91.821Z" transform="translate(288.647 234.22)" fill="#060001"/>
      <path d="M79.932,108.156c-3.886,15.542-7.771,31.085-12.212,46.072h31.64v9.992H0v-9.992H56.619a495.926,495.926,0,0,0,12.767-49.4ZM56.619,69.3V89.838H92.7V99.83H8.881V89.838H44.962V69.3ZM41.631,147.567,30.53,150.9a325.211,325.211,0,0,0-9.436-41.632l11.1-3.331A325.213,325.213,0,0,1,41.631,147.567Z" transform="translate(0 316.426)" fill="#060001"/>
      <path d="M77.329,87.053c0,27.754,14.987,50.513,44.407,67.721l-8.881,9.436c-21.648-13.877-35.526-30.53-42.187-49.4-8.326,19.428-23.314,36.081-44.407,49.958L19.6,154.218c30.53-19.428,46.072-41.632,46.072-67.166V80.392H40.693V70.4H77.329Z" transform="translate(89.537 321.448)" fill="#060001"/>
      <path d="M81.232,107.3A49.02,49.02,0,0,1,71.8,98.42v16.1H61.8V97.865A147.822,147.822,0,0,1,44.6,112.3l-5-8.881A74.926,74.926,0,0,0,58.473,88.428H41.82V79.547H61.8V69H71.8V79.547H88.448v8.881H74.015A57.715,57.715,0,0,0,86.227,98.975Zm6.106,62.725H79.011l-5-9.437H84.562a2.622,2.622,0,0,0,2.775-2.775V139.5H41.265v-8.881h99.361V139.5H97.884v21.093C97.884,166.7,94.554,170.026,87.337,170.026Zm-36.081-5-8.326-6.661a117.849,117.849,0,0,0,23.314-17.763l7.771,5.551A87.5,87.5,0,0,1,51.257,165.03Zm74.937-38.856H56.253v-8.881h70.5v8.881Zm11.1-14.432a101.95,101.95,0,0,1-18.873-14.987v17.763h-9.992V97.865c-5,5-10.547,9.436-16.653,14.432l-5-8.881A77.542,77.542,0,0,0,105.1,88.428H92.888V79.547h15.542V69h9.992V79.547h22.759v8.881H122.308a78.165,78.165,0,0,0,20.538,14.987Zm3.886,45.517-7.216,7.216c-9.437-5-18.318-10.547-25.534-17.208l8.326-6.106C123.973,147.822,132.3,152.818,141.181,157.259Z" transform="translate(180.903 315.066)" fill="#060001"/>
      <path d="M108.093,69.2h11.1v31.085h31.085v10.547H119.194v44.407h41.631v9.992H59.8v-9.992H79.783v-65.5h11.1v64.945h17.208V69.2Z" transform="translate(273.174 315.972)" fill="#060001"/>
      <path d="M2.6,171.021l8.326-9.992c7.216,6.661,14.987,9.992,24.424,9.992,8.326,0,13.877-3.886,13.877-9.992h0c0-5.551-3.331-8.326-17.763-11.657-16.653-3.886-26.089-8.881-26.089-23.314h0c0-13.322,11.1-22.759,26.644-22.759q16.653,0,28.309,9.992l-7.216,10.547c-7.216-5-13.877-7.771-21.093-7.771-7.771,0-12.767,3.886-12.767,9.436h0c0,6.106,3.331,8.881,18.318,12.212,16.653,3.886,24.979,9.992,24.979,22.759h0c0,14.432-11.657,23.314-27.754,23.314A47.266,47.266,0,0,1,2.6,171.021Z" transform="translate(11.832 471.888)" fill="#060001"/>
      <path d="M39.024,116.267H14.6V103.5H77.88v12.767H53.456v64.945H39.024Z" transform="translate(66.669 472.799)" fill="#060001"/>
      <path d="M27.8,148.462V103.5H41.677v44.407c0,14.432,7.216,22.2,19.428,22.2s19.428-7.216,19.428-21.648V103.5H94.41v43.852c0,23.314-13.322,34.971-33.305,34.971C40.567,182.878,27.8,171.221,27.8,148.462Z" transform="translate(126.949 472.799)" fill="#060001"/>
      <path d="M42.7,103.6H71.565c24.424,0,41.076,16.653,41.076,38.856h0c0,21.648-16.653,38.856-41.076,38.856H42.7Zm13.877,12.212v52.733H72.12c16.1,0,27.2-11.1,27.2-26.089h0c0-14.987-10.547-26.644-27.2-26.644Z" transform="translate(194.996 473.254)" fill="#060001"/>
      <path d="M58,103.6H71.877v77.712H58Z" transform="translate(264.743 473.254)" fill="#060001"/>
      <path d="M63.2,143.821h0c0-22.2,16.653-40.521,40.521-40.521s40.521,18.318,40.521,39.966h0c0,21.648-16.653,39.966-40.521,39.966C79.853,183.788,63.2,165.47,63.2,143.821Zm66.61,0h0c0-15.542-11.1-27.754-26.644-27.754s-26.089,12.212-26.089,27.2h0c0,14.987,11.1,27.754,26.644,27.754S129.81,158.809,129.81,143.821Z" transform="translate(288.647 471.888)" fill="#060001"/>
      <path d="M15.2,120.6H27.967L69.6,174.444V120.6H82.921v77.712H71.264L28.522,142.8v55.509H15.2V120.6Z" transform="translate(69.412 550.619)" fill="#060001"/>
      <path d="M62.805,120.5H75.572l34.415,78.267H95.555l-7.771-18.873H51.148l-7.771,18.873H29.5Zm19.983,47.182L69.466,136.6,56.144,167.682Z" transform="translate(134.73 550.163)" fill="#060001"/>
      <path d="M45.5,131.8H60.487v15.542H45.5Z" transform="translate(207.688 601.588)" fill="#060001"/>
      <path d="M50.8,120.6h57.729v12.212H64.677V152.8h38.856v12.212H64.677V186.1h44.407v12.212H50.8Z" transform="translate(231.961 550.619)" fill="#060001"/>
      <path d="M63.2,160.821h0c0-22.2,16.653-40.521,40.521-40.521s40.521,18.318,40.521,39.966h0c0,21.648-16.653,39.966-40.521,39.966C79.853,200.788,63.2,182.47,63.2,160.821Zm66.61,0h0c0-15.542-11.1-27.754-26.644-27.754s-26.089,12.212-26.089,27.2h0c0,14.987,11.1,27.754,26.644,27.754S129.81,175.809,129.81,160.821Z" transform="translate(288.647 549.253)" fill="#060001"/>
    </g>
  </svg>

export default Cover
