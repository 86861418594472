import React, { Component } from 'react';
import { connect } from 'react-redux'
import { loadFoundIn, loadHA, loadStaff } from "../../actions/about";
import './About.css'
import BodyClassName from 'react-body-classname';
import { Link } from 'react-router-dom';

class About extends Component {

  componentDidMount(){
    let {loadStaff, loadFoundIn, loadHA} = this.props
    loadStaff()
    loadHA()
    loadFoundIn()
  }

  renderTexts(text) {
    let segs = text.split('\n').map((a, i)=>{
      return <div key={i}>{a}</div>
    })
    return <div className={"content-text-lines"}>{segs}</div>
  }

  render() {
    let {
      ha, foundIn, staff,
      leftColWidth, rightColWidth, contentWidth,
      loadingAbout, loadingAboutError
    } = this.props
    if(loadingAbout){
      return null
    }else if(loadingAboutError){
      return null
    }
    return <BodyClassName className={`about`}>
      <div className={"about-content"}>
        <div className={"mcontent-navs"}>
          <Link className={"nav-home"} to={'/'}>HOME</Link>
        </div>
        {foundIn &&
        <div className={"content-container content-found-in"}>
          <div className={"content-text-left"} style={{width:leftColWidth}}>{foundIn.title}</div>
          <div className={"content-text-right"}  style={{width:rightColWidth}}>
            <div className={"about-found-in"}>
              {this.renderTexts(foundIn.content)}
            </div>
          </div>
        </div>
        }
        {staff &&
        <div className={"content-container"}>
          <div className={"content-text-left"} style={{width: leftColWidth}}>{staff.title}</div>
          <div className={"content-text-right"}  style={{width:rightColWidth}}>
            <div className={"about-staff"}>
              {this.renderTexts(staff.content)}
            </div>
          </div>
        </div>
        }
        {ha &&
        <div className={"content-container"}>
          <div className={"content-text-left"} style={{width:leftColWidth}}>{ha.title}</div>
          <div className={"content-text-right"}  style={{width:rightColWidth}}>
            <div className={"about-ha"}>
              {this.renderTexts(ha.content)}
            </div>
          </div>
        </div>
        }
      </div>
    </BodyClassName>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    staff: state.about.staff,
    foundIn: state.about.foundIn,
    ha: state.about.ha,
    loadingAbout: state.work.loadingAbout,
    loadingAboutError: state.works.loadingAboutError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadHA: ()=>dispatch(loadHA()),
    loadStaff: ()=>dispatch(loadStaff()),
    loadFoundIn: ()=>dispatch(loadFoundIn()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About)


