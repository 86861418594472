import React, { Component } from 'react';
import { connect } from 'react-redux'
import { loadWorks } from "../../actions/works";
import './Work.css'
import BodyClassName from 'react-body-classname';
import {LENS} from "../../constants";
import { Link } from 'react-router-dom';

class Works extends Component {
  state={
    scrollTop: 0
  }
  componentDidMount(){
    let {works} = this.props
    if(!works || works.length === 0){
      this.props.loadWorks()
    }
  }
  handleScroll() {
    let scrollTop = this.ref.scrollTop
    this.setState({
      scrollTop
    });
  }

  handleScroll2() {
    let scrollTop = this.ref2.scrollTop
    this.setState({
      scrollTop
    });
  }

  getWork(){
    let id = this.props.match.params.id
    let {works} = this.props
    if(!works){
      return {}
    }else{
      let index = -1
      works.forEach((w, i)=>{
        if(w.id===id){
          index=i
        }
      })
      if(index === -1){
        return {}
      }
      let current = works[index]
      let next = works[0]
      if(works.length-1 !== index){
        next = works[index+1]
      }
      let pre = works[works.length-1]
      if(index != 0){
        pre = works[index-1]
      }

      return {current, next, pre}
    }
  }
  renderContent() {
    let {work} = this.props
    if(!work) {
      return null
    }
  }
  renderCredits(work) {
    if(!work) {
      return null
    }
    let segs = work.credits.split('\n').map((a, i)=>{
      return <div key={i}>{a}</div>
    })
    return <div className={"content-text-lines"}>{segs}</div>
  }
  renderDescription(work) {
    if(!work) {
      return null
    }
    let segs = work.description.split('\n').map((a, i)=>{
      return <div key={i}>{a}</div>
    })
    return <div className={"content-text-lines"}>{segs}</div>
  }
  render() {
    let {scrollTop} = this.state
    let {windowWidth, loadingWorksError, loadingWorks, leftColWidth, rightColWidth} = this.props
    if(loadingWorks){
      return null
    }else if(loadingWorksError){
      return null
    }
    let {current, next, pre} = this.getWork()
    let images = null
    if(current && current.images){
      images = current.images
    }
    if(images) {
      images.sort((a,b)=>a.createdAt > b.createdAt?1:-1)
    }

    return <BodyClassName className={`work ${scrollTop>30?'scrolling':''}`}>
      <div className={"work-content"}>
        <div className={"mcontent-title"}>
          {current && current.title}
        </div>
        <div className={"mcontent-navs"}>
          <Link className={"nav-home"} to={'/'}>HOME</Link>
          {pre && <Link className={'nav-pre'} to={`/works/${pre.id}`}>PRE</Link>}
          {next && <Link className={'nav-next'} to={`/works/${next.id}`}>NEXT</Link>}
        </div>
        <div className={"mcontent-container"} onScroll={this.handleScroll2.bind(this)} ref={ref=>this.ref2=ref}>
          <div className={"work-credits"}>
            {this.renderCredits(current)}
          </div>
          <div className={"work-description"}>
            {this.renderDescription(current)}
          </div>
          {
            windowWidth < 768 &&
            <div className={"work-images"}>
              {
                images && images.map(img => {
                  if(img.mime !== 'video/mp4'){
                    return <img key={img.id} src={`${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,w_2000`}/>
                  }else{
                    return <video
                      autoPlay controls key={img.id}
                    >
                      <source src={`${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  }
                })
              }
            </div>
          }
        </div>
        <div className={"content-container content-header"} style={{zIndex:10000}}>
          <div className={"content-text-left"} style={{width:leftColWidth}}>
            <div>
            {current && current.title}
            </div>
          </div>
          <div className={"content-text-right"} style={{width:rightColWidth}}>
            <div className={"work-credits"}>
              {this.renderCredits(current)}
            </div>
          </div>
          <div className={"content-text-navs"} style={{width:LENS[3]}}>
            {pre && <Link className={'nav-pre'} to={`/works/${pre.id}`}>PRE</Link>}
            {next && <Link className={'nav-next'} to={`/works/${next.id}`}>NEXT</Link>}
          </div>
        </div>
        <div className={"work-content-scroll"} onScroll={this.handleScroll.bind(this)} ref={ref=>this.ref=ref}>
        {(current && current.description && current.description !== "") &&
        <div className={"content-container content-description"} style={{zIndex:1000}}>
          <div className={"content-text-left"} style={{width: leftColWidth}}/>
          <div className={"content-text-right"} style={{width:rightColWidth}}>
            <div className={"work-description"}>
              {this.renderDescription(current)}
            </div>
          </div>
        </div>
        }
        {(!current || current.description === "") &&
        <div className={"content-container content-description"} style={{paddingTop:150}}>
        </div>
        }
        {
          windowWidth >= 768 &&
        <div className={"work-images"}>
          {
            images && images.map(img => {

              if(img.mime !== 'video/mp4'){
                return <img key={img.id} src={`${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=image/resize,w_2000`}/>
              }else{
                return <video
                  autoPlay loop controls key={img.id} poster={`${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}?x-oss-process=video/snapshot,t_${(current.postertime || 1)*1000},f_jpg`}>
                  <source src={`${img.url.replace("http://studionaeo-media.oss-cn-beijing.aliyuncs.com","https://media.studionaeo.com")}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              }
            })
          }
        </div>
        }
        </div>
      </div>
    </BodyClassName>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    works: state.works.data,
    loadingWorks: state.works.loadingWorks,
    loadingWorksError: state.works.loadingWorksError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWorks: ()=>dispatch(loadWorks())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Works)


