import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './App.css';
import SideMenu from './SideMenu'
import windowSize from 'react-window-size';
import Home from './routes/Home/Home'
import Works from './routes/Works/Works'
import Work from './routes/Work/Work'
import About from './routes/About/About'
import Contact from './routes/Contact/Contact'
import {LENS, TEXTS} from './constants'


class App extends Component {
  getHeader() {
    let {windowWidth, windowHeight} = this.props
    windowWidth = windowWidth<1024?1024:windowWidth
    let sum = LENS[0]+LENS[1]+LENS[2]+LENS[3]
    let segs = []
    let dist = 0
    let showIndex = -1
    let last = false
    let showAll = false
    let contentWidth = windowWidth - (LENS[0]+LENS[1]+LENS[2]+LENS[3]+LENS[0]) - 10
    let leftColWidth =  LENS[0]+LENS[1]+LENS[2]+LENS[3]+LENS[1]
    let rightColWidth = 0
    for(let i=0; i<100; i++) {
      showIndex += 1
      TEXTS.forEach((t, j)=>{
        if(dist>windowWidth){
          return
        }
        dist += LENS[j]
        let className="header-seg header-seg-"+t
        if (showIndex%TEXTS.length === j && showIndex%TEXTS.length !== 3 && !last) {
          className += " header-show"
        }else if(j === 3 && dist+sum > windowWidth && !showAll && last){
          className += " header-show"
          rightColWidth = dist - (LENS[0]+LENS[1]+LENS[2]+LENS[3]+LENS[0])  - LENS[j] - leftColWidth - 48
          showAll = true
        }else{
          className += " header-hide"
        }
        if(showIndex === 3){
          last = true
        }
        segs.push(<Link key={i*4+j} to={"/"} className={className}>{t}</Link>)
      })
    }
    return {segs, contentWidth, leftColWidth, rightColWidth}
  }
  render() {
    let {windowWidth} = this.props
    let {segs, windowHeight, contentWidth, leftColWidth, rightColWidth} = this.getHeader()
    let params = {
      windowWidth,
      leftColWidth,
      rightColWidth,
      contentWidth,
      windowHeight
    }
    return (
      <div className="app">
        <Router>
          <div>
            <div className="header">
              <div className={"header-ln"}>
                {segs}
              </div>
            </div>
            <div className={"content"}>
              <SideMenu className={"left-col"}/>
              <div className={"right-col"} style={{width:contentWidth}}>
                <Route exact path="/" render={(props)=><Home
                  {...props}
                  {...params}
                />} />
                <Route exact path="/works" render={(props)=><Works
                  {...props}
                  {...params}
                />} />

                <Route exact path="/works/:id" render={(props)=><Work
                  {...props}
                  {...params}
                />} />
                <Route exact path="/about" render={(props)=><About
                  {...props}
                  {...params}
                />} />
                <Route exact path="/Contact" render={(props)=><Contact
                  {...props}
                  {...params}
                />} />
              </div>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default windowSize(App);

